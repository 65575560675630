@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Kristi&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

.font-sans {
  font-family: "Lato", sans-serif;
}
.font-serif {
  font-family: "DM Sans", sans-serif;
}
.font-alternate {
  font-family: "Kristi", cursive;
}

body {
  @apply bg-white font-sans font-normal;
}
body a {
  @apply no-underline text-blue-700;
}
.gray-shadow {
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.2);
}
.text-shad {
  text-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
}
.text-shad-wh {
  text-shadow: 0px 3px 1px 1px rgba(255, 255, 0255, 0.8);
}
nav .navbar-menu a {
  @apply font-sans font-medium tracking-wide;
}
/* nav .navbar-menu .submenu a { @apply flex items-center font-medium text-gray-700 hover:text-white hover:bg-cyan-800 py-2 px-3; } */
body main p {
  @apply mb-4;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "DM Sans,  sans-serif";
  line-height: 1.1em !important;
  letter-spacing: 0;
}
h1 {
  @apply text-3xl md:text-5xl lg:text-7xl mb-4;
}
h1.heading {
  @apply text-3xl md:text-5xl lg:text-7xl mb-4;
}
h2 {
  @apply text-4xl md:text-5xl lg:text-6xl mb-4;
}
h3 {
  @apply text-3xl md:text-4xl lg:text-5xl mb-4;
}
h4 {
  @apply text-2xl md:text-3xl lg:text-4xl mb-4;
}
h5 {
  @apply text-xl md:text-2xl lg:text-3xl mb-4;
}
h6 {
  @apply text-lg md:text-xl lg:text-2xl mb-4;
}

.page-heading {
  @apply bg-cover bg-no-repeat bg-center relative overflow-hidden;
}
.page-heading:before {
  @apply bg-fixed absolute bg-neutral-900 bg-opacity-60 bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden;
}

.slider-sec {
  background-image: linear-gradient(0deg, #171717 40%, transparent 35%),
    url(https://res.cloudinary.com/dlpitjizv/image/upload/f_auto/v1686680314/impact/Home_Hero_Background_D_f9a09d8c5f.jpg);
  background-position: center;
}

.gradient-1 {
  background-image: linear-gradient(0deg, #171717 40%, transparent 35%);
  background-position: center;
}
.gradient-2 {
  background-image: linear-gradient(0deg, transparent 40%, #171717 35%);
  background-position: center;
}
.gradient-ltr {
  background-image: linear-gradient(90deg, #3730a3 60%, transparent 40%);
  background-position: center;
}

.parallex1 {
  @apply bg-cover bg-fixed bg-no-repeat bg-center relative overflow-hidden w-full;
}
.parallex1:before {
  @apply absolute bottom-0 bg-indigo-950 bg-opacity-90 left-0 right-0 top-0 w-full overflow-hidden transform;
}

.orange-tint {
  @apply bg-cover bg-no-repeat bg-center relative overflow-hidden w-full;
}
.orange-tint:before {
  @apply bg-fixed absolute bottom-0  bg-gradient-to-r  from-orange-500 left-0 right-0 top-0 w-full overflow-hidden transform;
}

.black-tint {
  @apply bg-cover bg-no-repeat bg-center relative overflow-hidden w-full;
}
.black-tint:before {
  @apply bg-fixed absolute bottom-0 bg-black bg-opacity-70 left-0 right-0 top-0 w-full overflow-hidden transform;
}

.heading-vert {
  writing-mode: vertical-rl;
  rotate: 180deg;
}

.embla {
  overflow: hidden;
}
.embla__container {
  display: flex;
}
.embla__slide {
  flex: 0 0 100%;
  min-width: 0;
}

@layer utilities {
  .text-outline {
    -webkit-text-stroke-width: 1px; /* Define the width of the outline */
    -webkit-text-stroke-color: #dad8d8; /* Define the color of the outline */
  }

  .text-outline-white {
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #ffffff;
  }

  .text-outline-2 {
    -webkit-text-stroke-width: 2px; /* Wider stroke */
    -webkit-text-stroke-color: #dad8d8;
  }
}
